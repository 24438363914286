import React, { Component } from 'react';
import { languages } from '../../../data/langauges'
class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const { locale } = this.props
        return (
            <React.Fragment>
                <div className='about-us-section pb-4 mt-5' id='about-us'>
                    <div className='row mx-0 pt-5'>
                        <div className='col-md-10 col-12 mx-auto'>
                            <div className='header-wrapper text-center'>
                                <h4 className='font-large text-black weight-600'> {languages[locale]['about_us']}</h4>
                            </div>

                            <div className='py-3 text-wrapper'>
                                <p className='font-Lsmall text-gold-500 weight-500 text-center'>
                                    {languages[locale]['about_us_text']}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AboutUs;