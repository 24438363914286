export const languages = {
    en: {
        home: 'Home',
        about_us: 'About us',
        competitions: 'Competitions',
        contact_us: 'Contact us',
        login: 'Login',

        sabq: 'sabq',
        download_our_app: 'Download Our App',
        need_help: 'Need help ?',
        uploaded: 'File uploaded successfully',
        call_us: 'Call us',
        email_us: 'Email us',
        follow_us: 'Follow us',
        code_sent_with_10min: 'The code sent via email please notice that it will expire after 10m',
        enroll_now: 'Enroll now',
        enroll_now_and_become_the_winner: 'Enroll now and become the first winner',
        reject_msg: 'Sorry your request haas been rejected from the admin',
        first_position: 'First position',
        second_position: 'second position',
        third_position: 'third position',
        riyal: 'Riyal',

        subject: 'Subject',
        email: 'Email',
        write_your_message: 'Write your message',
        send: 'Send',

        skip: 'Skip',

        password: 'Password',
        forget_password: 'Forget password',
        change_password: 'Change password',
        activate_account: 'Activate account',
        activate: 'Activate',
        send_again: 'Resend',
        new_password: 'New password',
        renew_password: 'New password again',
        current_password: 'Current password',
        password_changed: 'Password changed succssfully',

        // registeration
        judge: 'Judge',
        competitor: 'Competitor',
        continue: 'Continue',

        this_field_required: 'This field is required',
        wrong_email: 'This email is wrong',
        min_8_chars: 'Enter min 8 digits',
        pdf_or_word: 'Upload only pdf or word file',
        first_name: 'First Name',
        second_name: 'Last Name',
        third_name: 'Third Name',
        last_name: 'Fourth Name',
        phone: 'Phone number',
        confirm_password: 'Confirm password',
        country: 'Country',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        upload_cv: 'Upload CV',
        uploading: 'Uploading...',
        password_not_identical: 'Password not identical',
        back: 'Back',
        new_user: 'New user',

        code: 'Code',
        change: 'Change',

        profile: 'Profile',
        enrolled_in_competitons: 'Enrolled in competitions',
        rest_of_competitions: 'Rest of Competitons',
        ended_competitions: 'Ended competitons',
        evaluated_competitons: 'Evaluated Competitions',
        num_of_enroll: 'Enrollments',
        num_of_eval: 'Evaluations',
        logout: 'Logout',

        to_eval_competitons: 'Evaluate Competitions',
        edit: 'Edit',

        bank_info: 'Banking information',
        bank_name: 'Bank name',
        account_num: 'Account number',
        fast_code: 'IBAN number',

        personal_info: 'Personal information',
        name: 'Name',

        no_competitions: 'No exist competitions',
        no_participations: 'There are no participations exist',
        no_ended_competitions: 'No ended competitons yet',
        start_eval: 'Start evaluation',

        details: 'Details',

        competition: 'Competition',
        evaluation: 'Evaluation',

        competition_details: 'Competition Details',

        enroll_now: 'Enroll Now',

        level_name: 'Level name',

        all_participations_evaluated: 'All participations evaluated',

        from_competition: 'from the competition',
        evaluation_time: 'Evaluation time',
        total_score: 'Total score',
        save_eval: 'Save Evaluation',

        level_details: 'Level details',
        order_particitpation_to_eval: 'Start evaluation',
        evaluated_successfully: 'Evaluation Done',
        evaluation_another: 'Evaluate another',
        not_evaluated: 'Not evaluated yet',
        not_particpated_yet: 'Not Participated yet',
        video_audio_only: 'The only accepted file type are mp4, WebM, Ogg or mp3',
        video_audio_size: 'The file must be less than 25 mega byte',
        image_only: 'It must be image file',
        image_size: 'The file must be less than 2 mega byte',
        cv_size: 'The file must be less than 3 mega byte',
        cv_not_uploaded: 'CV not uploaded yet',
        upload_file_to_eval: 'Uplaod file to be evaluated',

        participated_done: 'Participated Successfully',
        will_be_called: 'You will be notified when evaluated',
        back_to_home: 'Back to home',

        judge_can_not_participate: 'Judge can not participate in a competition',
        email_wrong: 'Please enter right email',
        sent_successfully: 'Sent successfully',
        competiton: 'Competiton',
        user_id: 'User id',

        video_not_working: 'This browser not support this file type so please click on the eye icon to download it',

        write_your_notes: 'any comments?',
        something_wrong: 'something went wrong please try again after 5 sec',
        levels: 'levels',
        level_without_the: 'level',
        registered_successfully: 'Registered successfully',
        account_under_review: 'Account is revewing now by the admin',
        about_us_text: 'The idea of ​​the administration of the Holy Quran Competition for the Most Beautiful Recitation and the Most Beautiful Voice of the Call to Prayer started by the Entertainment Authority in the Kingdom of Saudi Arabia, and from the earliest beginnings planning and preparation was made to be the strongest and best system for managing contests and competitions. Participants can join competitions from anywhere in the world with participation With a video, audio clip, or something else',

        privacy_policy: 'Privacy policy',
    },
    ar: {
        home: 'الرئيسية',
        about_us: 'عن الموقع',
        competitions: 'المسابقات',
        contact_us: 'تواصل معنا',
        login: 'تسجيل دخول',
        registered_successfully: 'تم التسجيل بنجاح',
        account_under_review: 'حسابكم قيد المراجعة من قبل الإدارة',
        sabq: 'سبق',
        download_our_app: 'حمل التطبيبق',
        need_help: 'تحتاج الى مساعدة؟',

        uploaded: 'تم رفع الملف بنجاح',
        code_sent_with_10min: 'تم ارسال الكود الى البريد برجاء العلم انه يتنهى بعد 10 دقائق من إرساله',
        call_us: 'اتصل بنا',
        email_us: 'راسلنا عبر البريد الإلكترونى',
        follow_us: 'تابعنا عبر مواقع التواصل',

        enroll_now: 'اشترك الآن',

        enroll_now_and_become_the_winner: 'شارك الآن و كن الفائز الأول',

        first_position: 'المركز الاول',
        second_position: 'المركز الثاني',
        third_position: 'المركز الثالث',
        riyal: 'ريال',

        subject: 'الموضوع',
        email: 'البريد الإلكترونى',
        write_your_message: 'أكتب رسالتك هنا',
        send: 'إرسال',

        skip: 'تخطي',
        reject_msg: 'لقد تم رفض طلبكم من قبل الادمن',
        password: 'كلمة المرور',
        forget_password: 'نسيت كلمة المرور',
        change_password: 'تغيير كلمة المرور',
        activate: 'تفعيل',
        send_again: 'إعادة إرسال',
        activate_account: 'تفعيل الحساب',
        new_password: 'كلمة المرور الجديدة',
        current_password: 'كلمة المرور الحالية',
        renew_password: 'إعادة كلمة المرور الجديدة',
        password_changed: 'تم تغيير كلمة المرور بنجاح',
        judge: 'مقييم',
        competitor: 'متسابق',
        continue: 'متابعه',

        this_field_required: 'هذه الخانة مطلوبة',
        wrong_email: 'برجاء ادخال بريد صحيح',
        min_8_chars: 'أدخل ما لا يقل عن 8 أحرف',
        pdf_or_word: 'برجاء رفع ملف بي دي إف او وورد',
        first_name: 'الاسم الاول',
        second_name: 'الاسم الأخير',
        third_name: 'الاسم الثالث',
        last_name: 'الاسم الاخير',
        phone: 'رقم الجوال',
        confirm_password: 'تأكيد كلمة المرور',
        country: 'الدولة',
        gender: 'الجنس',
        male: 'ذكر',
        female: 'أنثى',
        upload_cv: 'رفع السيرة الذاتية',
        uploading: '...جاري رفع الملف',
        password_not_identical: 'كلمة المرور غير متطابقة',
        back: 'العودة',
        new_user: 'مستخدم جديد',

        code: 'الكود',
        change: 'تغيير',
        competiton: 'مسابقة',
        profile: 'الملف الشخصي',
        rest_of_competitions: 'باقي المسابقات',
        enrolled_in_competitons: 'المسابقات المشترك بها',
        ended_competitions: 'المسابقات المنتهية',
        evaluated_competitons: 'سجل التقييمات',
        num_of_enroll: 'عدد المشاركات',
        num_of_eval: 'عدد التقييمات',
        to_eval_competitons: 'المسابقات المسنده إليه',
        logout: 'تسجل الخروج',

        edit: 'تعديل',


        bank_info: 'المعلومات البنكية',
        bank_name: 'اسم البنك',
        account_num: 'رقم الحساب',
        fast_code: 'الكود السريع',

        personal_info: 'المعلومات الشخصية',
        name: 'الاسم',

        no_competitions: 'لا يوجد مسابقات متاحة',
        no_participations: 'لا يوجد مشاركات',
        no_ended_competitions: 'لا يوجد مسابقات منتهية',
        start_eval: 'ابدأ التقييم',

        details: 'التفاصيل',

        competition: 'المسابفة',
        evaluation: 'التقييم',
        competition_details: 'تفاصيل المسابقة',

        enroll_now: 'إشترك الان',

        level_name: 'اسم المرحلة',

        all_participations_evaluated: 'تم تقييم كافة المشاركات',

        from_competition: 'من المسابقة',
        evaluation_time: 'مدة التقييم',
        total_score: 'المجموع الكلي',
        save_eval: 'حفظ التقييم',

        level_details: 'تفاصيل المرحلة',
        order_particitpation_to_eval: 'طلب مقطع للتقييم',
        evaluated_successfully: 'تم التقييم بنجاح',
        evaluation_another: 'تقييم اخر',
        not_evaluated: 'لم يتم التقييم بعد',
        not_particpated_yet: 'لم يتم التقديم بعد',
        video_audio_only: 'انواع الملفات المقبوله فقط هي mp4, WebM, Ogg, mp3',
        video_audio_size: 'يجب ان يكون الملف اقل من 25 ميجا',
        image_only: 'يجب ان يكون صورة فقط',
        image_size: 'يجب ان يكون الملف اقل من 5 ميجا',
        cv_size: 'يجب ان يكون الملف اقل من 3 ميجا',
        cv_not_uploaded: 'لم يتم رفع السي في',

        upload_file_to_eval: 'تقديم مقطع للتقييم',
        something_wrong: 'حدث خطأ ما من فضلك حاول بعد 5 ثواني',
        participated_done: 'تم تقديم المشاركة',
        will_be_called: 'سيتم تنبيهكم بعدمايتم تقييم مشاركتكم',
        back_to_home: 'الرجوع للصفحة الرئيسية',

        judge_can_not_participate: 'لا يمكن لمقيم الاشتراك في مسابقة',
        email_wrong: 'برجاء ادخال بريد صحيح',
        sent_successfully: 'تم الارسال بنجاح',

        user_id: 'رقم المتسابق',
        video_not_working: 'هذا المتصفح لا يدعم هذا الملف برجاء الضغط على العين للتحميل',

        write_your_notes: 'أي تعليق؟',
        level_without_the: 'مرحلة',
        levels: 'المراحل',

        about_us_text: 'بدأت فكرة سبق لإدارة مسابقة القران الكريم أجمل تلاوة ومسابقة الأذان أجمل صوت بالأذان المقامة من قبل هيئة الترفيه بالمملكة العربية السعودية ، ومن باكورة البدايات تم التخطيط والتهيئة ليكون "سبق" المنظومة الأقوى والأفضل لإدارة المسابقات والمنافسات ويتمكن المشاركون من الانضمام للمسابقات من أي مكان في العالم بالمشاركة بفيديو أو مقطع صوتي أو غير ذلك',

        privacy_policy: 'سياسة خصوصية',
    },
}
