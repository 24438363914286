import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { AppContext } from "../../context/appStateContext";
import ProfileAside from "../../components/asideBar";
import Profile from "./profile/index";
import CompetitionsC from "./competitor/competitions";
import UnEnrolled from "./competitor/unenrolled";
import CompetitionR from "./resident/competitions";
import History from "./resident/history";
import EndedCompetiton from "./resident/ended/ended";
import EndedDetails from "./resident/ended/endedDetails";
import axios from "../../helperComponents/axios";
import Loader from "../Loader";
import RegisteredSuccessfly from "../auth/registered-successfully";
class UserMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      second_name: "",
      third_name: "",
      fourth_name: "",
      email: "",
      gender: "",
      image: "",
      country: "",
      phone: "",
      loader: true,
      bank_accounts_count: 0,
      bankAccounts: [],
      cities: []
    };
  }
  componentDidMount() {
    let link = localStorage["type"] === "COMPETITOR" ? "competitor" : "judge";
    axios({
      method: "get",
      url: `/${link}`
    })
      .then(res => {
        const {
          first_name,
          second_name,
          third_name,
          fourth_name,
          email,
          gender,
          image,
          country,
          phone,
          bankAccounts,
          bank_accounts_count,
          competitions_count,
          rates_count
        } = res.data.data;
        this.setState({
          first_name,
          second_name,
          third_name,
          fourth_name,
          email,
          gender,
          image,
          phone,
          country,
          competitions_count: competitions_count? competitions_count:rates_count,
          bankAccounts: bankAccounts ? bankAccounts[0] : undefined,
          bank_accounts_count,
          loader: false
        });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
        this.setState({ loader: "redirect" });
      });
    axios({
      method: "get",
      url: `/countries`
    })
      .then(res => {
        this.setState({ cities: res.data.data });
      })
      .catch(err => {
        console.log("AXIOS ERROR: ", err);
      });
  }
  render() {
    const { locale } = this.props;
    if (localStorage["status"] !== "APPROVED" && localStorage['type'] === 'JUDGE' && localStorage["login_token"]) {
      return (
        <div className='upload-section'>
                <div className='upload-container' style={{paddingTop:0,minHeight:"initial"}}>
                <div className='announcment d-flex flex-column justify-content-center align-items-center'>
            <RegisteredSuccessfly locale={locale} />
          </div>
          </div>
        </div>
      );
    }
    else if(localStorage["status"] !== "APPROVED" && localStorage['type'] === 'COMPETITOR' && localStorage["login_token"]){
      console.log('wwwwwwwwwwwwwwwwwwwwwwwwwww')
      return <Redirect to={`auth`} />;
    }
    if (this.state.loader === "redirect"){
      console.log('redirecttttttttttttttt')
      return <Redirect to={`/`} />;
    }
    return this.state.loader ? (
      <Loader isLoading={true} />
    ) : localStorage["login_token"] ? (
      <div className="container-fluid inside-container">
        <div className="row justify-content-center" style={{ marginLeft: 0 }}>
          <Switch>
            <Route
              path={`/${locale}/user/profile`}
              exact
              render={props => (
                <Profile
                  data={this.state}
                  context={this.props.context}
                  locale={locale}
                />
              )}
            />
            <Route
              path={`/${locale}/user/current-competitions`}
              render={props => {
                return localStorage["type"] === "COMPETITOR" ? (
                  <CompetitionsC locale={locale} data={this.state} />
                ) : (
                  <CompetitionR data={this.state} locale={locale} />
                );
              }}
            />
            <Route
              path={`/${locale}/user/history`}
              render={props => {
                return localStorage["type"] === "COMPETITOR" ? (
                  <UnEnrolled locale={locale} data={this.state} />
                ) : (
                  <History data={this.state} locale={locale} />
                );
              }}
            />
            <Route
              path={`/${locale}/user/ended/:id`}
              render={props => <EndedDetails locale={locale} {...props} />}
            />
            <Route
              path={`/${locale}/user/ended`}
              render={props => (
                <EndedCompetiton locale={locale} data={this.state} />
              )}
            />

            {/* default */}
            <Route
              render={props => <Redirect to={`/${locale}/user/profile`} />}
            />
          </Switch>
        </div>
      </div>
    ) : (
      <Redirect to={`/${locale}/`} />
    );
  }
}

export default props => (
  <AppContext.Consumer>
    {context => {
      return <UserMain {...props} context={context} />;
    }}
  </AppContext.Consumer>
);
