import React, { Component, Fragment } from "react";
import { AppContext } from "../../../context/appStateContext";
import { Link } from "react-router-dom";
import axios from "../../../helperComponents/axios";
import Loader from "../../Loader";
import ProfileAside from "../../../components/asideBar";
import { languages } from "../../../data/langauges";
// {languages[locale]['email']}
class CompetitionR extends Component {
  constructor(props) {
    super(props);
    this.state = { competitions: [], loader: true };
  }
  componentDidMount() {
    axios({
      method: "get",
      url: `/judge/${localStorage["user_id"]}/competitions`,
    })
      .then((res) => {
        this.setState({ competitions: res.data.data, loader: false });
        console.log("RESPONSE RECEIVED: ", res);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  }
  render() {
    /*
        this is to the the registered in competitons for Judge
        */
    const { locale } = this.props;
    const noCompetitions = this.state.competitions.length === 0 ? true : false;
    console.log("noCompetitions", noCompetitions);
    return this.state.loader ? (
      <Loader isLoading={true} />
    ) : (
      <Fragment>
        <ProfileAside
          data={this.props.data}
          context={this.props.context}
          locale={locale}
        />

        <div className="competetions-white-card-section col-md-9">
          <div className="slider-container row justify-content-center">
            <div
              className="competitions-slider-wrapper owl-theme row col-md-11 competitons_container"
              style={{
                justifyContent: noCompetitions ? "center" : "initial",
                marginTop: noCompetitions ? "10%" : 0,
              }}
            >
              {noCompetitions ? (
                <Fragment>
                  <img height="50" src="/images/search_404.png" />
                  <h2 className="text-purple-900 font-large text-center mt-5">
                    {languages[locale]["no_competitions"]}
                  </h2>
                </Fragment>
              ) : (
                this.state.competitions.map((value, key) => (
                  <React.Fragment key={key}>
                    <div
                      className="col-lg-4 col-md-6"
                      style={{ marginTop: "75px", marginBottom: "50px" }}
                    >
                      <div
                        className="white-card competiotion-card"
                        style={{ maxWidth: "416px" }}
                      >
                        <div className="img-wrapper">
                          <div className="img-holder">
                            <img
                              src={
                                value.image
                                  ? value.image
                                  : "/images/competitions/quran.png"
                              }
                              alt="competition-img"
                            />
                          </div>
                        </div>
                        <div className="copetition-name mt-3">
                          <div className="text-purple-1000 weight-500 font-medium text-center">
                            {value.name} - {value.id}
                          </div>
                        </div>
                        <div className="dates mt-2">
                          <div className="text-purple-1000 weight-500 font-small text-center font-family-en">
                            {value.from} - {value.to}
                          </div>
                        </div>
                        <hr />
                        <div className="competition-description pb-2">
                          <div className="font-small weight-500 text-gold-450 text-center">
                            {value.description}
                          </div>
                        </div>
                        <div className="btn-wrapper mb-md-n5 mb-n3 mx-md-n4 mx-n2">
                          <Link
                            to={`/${locale}/competition/${value.id}/${value.name}`}
                          >
                            <a
                              href=""
                              className="btn btn-purple-900 w-100 font-Lsmall weight-500"
                              style={{
                                borderTopRightRadius: "0",
                                borderTopLeftRadius: "0",
                              }}
                            >
                              {languages[locale]["start_eval"]}
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default (props) => (
  <AppContext.Consumer>
    {(context) => {
      return <CompetitionR {...props} context={context} />;
    }}
  </AppContext.Consumer>
);
