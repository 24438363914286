import React, { Component } from "react";
import { AppContext } from "../../context/appStateContext";
import { NavLink, Link } from "react-router-dom";
import { languages } from "../../data/langauges";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderFooter = this.renderFooter.bind(this);
  }
  renderFooter() {
    const { locale } = this.props;
    return (
      <React.Fragment>
        <footer>
          <div className="footer-container">
            <div className="row mx-0">
              <div className="col-md-11 col-12 mx-auto">
                <div className="row">
                  <div className="col-lg-2 col-6 mx-lg-0 mx-auto">
                    <div className="footer-header">
                      <h5 className="font-medium text-gold-500 weight-500">
                        {languages[locale]["sabq"]}
                      </h5>
                    </div>
                    <div className="footer-list mt-md-3">
                      <div className="footer-list-item my-2">
                        <a
                          href={`/${locale}/#about-us`}
                          className="link-gold-500 font-small weight-500"
                        >
                          {languages[locale]["about_us"]}
                        </a>
                      </div>
                      <div className="footer-list-item my-2">
                        <NavLink
                          to="/"
                          className="link-gold-500 font-small weight-500"
                        >
                          {languages[locale]["home"]}
                        </NavLink>
                      </div>
                      <div className="footer-list-item my-2">
                        <a
                          href={`/${locale}/#contact-us`}
                          className="link-gold-500 font-small weight-500"
                        >
                          {languages[locale]["contact_us"]}
                        </a>
                      </div>
                      <div className="footer-list-item my-2">
                        <a
                          href={`/${locale}/privacy-policy`}
                          className="link-gold-500 font-small weight-500"
                        >
                          {languages[locale]["privacy_policy"]}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 col-6 mx-lg-0 mx-auto">
                    <div className="footer-header">
                      <h5 className="font-medium text-gold-500 weight-500">
                        {languages[locale]["competitions"]}
                      </h5>
                    </div>
                    <div className="footer-list mt-md-3">
                      <div className="footer-list-item my-2">
                        <a
                          href=""
                          className="link-gold-500 font-small weight-500"
                        >
                          {languages[locale]["about_us"]}
                        </a>
                      </div>
                      <div className="footer-list-item my-2">
                        <NavLink
                          to="/"
                          className="link-gold-500 font-small weight-500"
                        >
                          {languages[locale]["home"]}
                        </NavLink>
                      </div>
                      <div className="footer-list-item my-2">
                        <a
                          href=""
                          className="link-gold-500 font-small weight-500"
                        >
                          {languages[locale]["contact_us"]}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3  col-7 my-lg-0 my-3 mx-auto d-flex flex-column pb-lg-4 pb-2">
                    <div className="footer-header w-100">
                      <h5 className="font-large text-gold-500 weight-500 mb-md-4">
                        {languages[locale]["download_our_app"]}
                      </h5>
                    </div>
                    <div className="footer-body">
                      <div className="row">
                        <div className="col-6">
                          <a
                            className="d-block"
                            href="https://play.google.com/store/apps/details?id=com.sabq.win.Sabq"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="w-100"
                              src="/images/stores-icons/play-store.png"
                              alt="play store"
                            />
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            className="d-block"
                            href="https://apps.apple.com/eg/app/%D8%AA%D8%B7%D8%A8%D9%8A%D9%82-%D8%B3%D8%A8%D9%82/id1534112194"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="w-100"
                              src="/images/stores-icons/apple-store.png"
                              alt="apple store"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6 mx-lg-0 mx-auto">
                    <div className="footer-header">
                      <h5 className="font-large text-gold-500 weight-500">
                        {languages[locale]["need_help"]}
                      </h5>
                    </div>
                    <div className="footer-list">
                      <div className="footer-list-item my-3">
                        <div className="list-item-header font-small weight-500 text-gold-500">
                          {languages[locale]["email_us"]}
                        </div>
                        <a
                          href="mailto:Info@sabq.com"
                          className="link-gold-500 font-Lsmall weight-500 font-family-en"
                        >
                          info@wtsaudi.com
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-7 mx-lg-0 mx-auto my-lg-0 my-4 order-lg-last order-first">
                    <div className="logo-wrapper h-100 px-lg-0 px-4">
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <img className="w-75" src="/images/logo/logo.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
  render() {
    const { context } = this.props;
    return (
      <React.Fragment>
        {context.state.footer.type === "full" ? (
          this.renderFooter()
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default (props) => (
  <AppContext.Consumer>
    {(context) => {
      return <Footer {...props} context={context} />;
    }}
  </AppContext.Consumer>
);
