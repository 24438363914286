import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Input, Select, Icon, notification } from "antd";
import axios from "../../../../helperComponents/axios";
import { languages } from "../../../../data/langauges";
// {languages[locale]['email']}
const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: "center",
    justifyContent: "center",
    float: "left",
    width: "90%",
    marginTop: 90,
    marginBottom: 10,
  },
  textField: {
    marginLeft: 25,
    marginRight: 30,
  },
  card: {
    borderRadius: 10,
    boxShadow: "0 3 10 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#FFFFFF",
    borderLeft: "10px solid #65425f",
  },
}));
const submit = (data) => {
  const id = localStorage["user_id"];
  const link = localStorage["type"] === "COMPETITOR" ? "competitor" : "judge";
  axios({
    method: "put",
    url: `${link}/${id}/update-profile`,
    data: data,
  })
    .then((res) => {
      console.log("RESPONSE RECEIVED: update info", res);
      window.location.reload();
    })
    .catch((err) => {
      if (
        err.response &&
        err.response.status == 422 &&
        err.response.data.errors.email
      ) {
        console.log("AXIOS ERROR: update info", err.response);
      }
      if (err.response) {
        let obj = err.response.data.errors;
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            notification.error({
              message: obj[key][0],
            });
          }
        }
      }
    });
};
export default function PersonalInfo(props) {
  const classes = useStyles();
  const [first_name, setFirst] = useState(props.data.first_name);
  const [second_name, setSecond] = useState(props.data.second_name);
  const [email, setEmail] = useState(props.data.email);
  const [phone, setPhone] = useState(props.data.phone);
  const [country_id, setCity] = useState(props.data.country.id);
  const [gender, setGender] = useState(
    props.data.gender === "MALE" ? "1" : "2"
  );

  const { locale } = props;
  return (
    <div className={classes.content}>
      <Card className={classes.card}>
        <CardContent>
          <div className="font-large weight-500 text-purple-900">
            <span>{languages[locale]["personal_info"]}</span>
          </div>
          <div className="form-wrapper form-wrapper-card">
            <form className="form">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-input-wrapper">
                    <div className="input-wrapper">
                      <Input
                        className="form-input"
                        value={first_name}
                        name="first_name"
                        onChange={(e) => {
                          setFirst(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-input-wrapper">
                    <div className="input-wrapper">
                      <Input
                        className="form-input"
                        value={second_name}
                        name="second_name"
                        onChange={(e) => {
                          setSecond(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-input-wrapper">
                    <div className="input-wrapper">
                      <Input
                        className="form-input"
                        value={email}
                        name="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-input-wrapper">
                    <div className="input-wrapper">
                      <Input
                        className="form-input"
                        value={phone}
                        style={{ direction: "ltr" }}
                        name="phone"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-input-wrapper">
                    <div className="input-wrapper">
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        labelInValue
                        defaultValue={{ key: country_id }}
                        className="form-input"
                        placeholder={languages[locale]["country"]}
                        name="country_id"
                        onChange={(value) => {
                          console.log("value0", value);
                          setCity(value.key);
                        }}
                      >
                        {props.data.cities.map((city, i) => (
                          <Option key={city.id} value={city.id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="form-input-wrapper">
                    <div className="input-wrapper">
                      <Select
                        labelInValue
                        defaultValue={{ key: gender }}
                        onChange={(value) => {
                          setGender(value.key);
                        }}
                        className="form-input"
                        placeholder={languages[locale]["male"]}
                      >
                        <Option value="1">{languages[locale]["male"]}</Option>
                        <Option value="2">{languages[locale]["female"]}</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-6 mb-2">
                  <div className="btn-wrapper">
                    <button
                      type="button"
                      className="btn btn-purple-900 font-Lsmall w-100"
                      onClick={() =>
                        submit({
                          first_name,
                          second_name,
                          third_name: first_name,
                          fourth_name: second_name,
                          email,
                          phone,
                          country_id,
                          gender,
                        })
                      }
                    >
                      {languages[locale]["change"]}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
