import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  NavLink,
} from "react-router-dom";
import { AppContext } from "../../context/appStateContext";
import Login from "./login/index";
import Register from "./register/index";
import ForgotPassword from "./forgot_password";
import ResetPassword from "./reset_password";
import ConfirmEmail from "./confirm_email";
import RegisteredSuccessfuly from "./registered-successfully";
import { languages } from "../../data/langauges";
import Loader from "../Loader";
class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.props.context.setContext("navbar", {
      type: "full",
      color: "tranparentTop",
    });
    this.props.context.setContext("footer", { type: "none" });
    this.renderAuthTabs = this.renderAuthTabs.bind(this);
  }
  handleLoader(value) {
    this.setState({ loading: value });
  }
  renderAuthPages() {
    const { locale } = this.props;
    console.log("locale", this.props.context);
    return (
      <React.Fragment>
        {localStorage["login_token"] ? (
          <Redirect to={`/${locale}/user/current-competitions`} />
        ) : (
          <Switch>
            <Route
              path={`/${locale}/auth/login`}
              exact
              render={(props) => (
                <Login
                  handleLoader={this.handleLoader}
                  {...props}
                  locale={locale}
                />
              )}
            />
            <Route
              path={`/${locale}/auth/register`}
              render={(props) => <Register {...props} locale={locale} />}
            />
            <Route
              path={`/${locale}/auth/forgot-password`}
              exact
              render={(props) => <ForgotPassword {...props} locale={locale} />}
            />
            <Route
              path={`/${locale}/auth/reset-password/:email?`}
              exact
              render={(props) => <ResetPassword {...props} locale={locale} />}
            />

            {/* default */}
            <Route
              render={(props) => <Redirect to={`/${locale}/auth/login`} />}
            />
          </Switch>
        )}
      </React.Fragment>
    );
  }
  renderAuthTabs() {
    const { locale } = this.props;
    const temp =
      localStorage["login_token"] &&
      localStorage["login_token"] !== "" &&
      localStorage["status"] !== "APPROVED" &&
      localStorage["type"] === "JUDGE";
    if (temp) {
      return <Redirect to={`/${locale}/auth/registered-successfuly`} />;
    }
    const status =
      localStorage["status"] === "PENDING" &&
      localStorage["type"] === "COMPETITOR" &&
      localStorage["login_token"];
    return (
      <React.Fragment>
        <div className="auth-tabs-container">
          {!status && (
            <div className="tabs-links-wrapper">
              <NavLink
                to={`/${locale}/auth/login`}
                className="tab-link font-Lsmall weight-500"
              >
                {languages[locale]["login"]}
              </NavLink>
              <NavLink
                to={`/${locale}/auth/register`}
                className="tab-link font-Lsmall weight-500"
              >
                {languages[locale]["new_user"]}
              </NavLink>
            </div>
          )}
          <div className="tabs-body-wrapper">
            {status ? this.renderConfirmation() : this.renderAuthPages()}
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderConfirmation() {
    const { locale } = this.props;
    return <ConfirmEmail locale={locale} />;
  }

  render() {
    const { locale } = this.props;
    if (this.state.loading) {
      return <Loader isLoading={true} />;
    }
    return (
      <React.Fragment>
        <div className="auth-section">
          <div className="auth-container">
            {localStorage["login_token"] !== "" &&
            localStorage["status"] === "APPROVED" ? (
              <Redirect to={`/${locale}/user/current-competitions`} />
            ) : (
              <Router>
                <Switch>
                  <Route
                    path={`/${locale}/auth/confirm-email`}
                    exact
                    render={(props) => (
                      <ConfirmEmail {...props} locale={locale} />
                    )}
                  />
                  <Route
                    path={`/${locale}/auth/registered-successfuly`}
                    exact
                    render={(props) => (
                      <RegisteredSuccessfuly {...props} locale={locale} />
                    )}
                  />
                  <Route
                    path={`/${locale}/auth/`}
                    render={(props) => this.renderAuthTabs()}
                  />
                </Switch>
              </Router>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default (props) => (
  <AppContext.Consumer>
    {(context) => {
      return <Auth {...props} context={context} />;
    }}
  </AppContext.Consumer>
);
