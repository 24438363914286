export const indexInClass = (node, className) => {
    var myClass = document.getElementsByClassName(className)
    var num = 0;
    for (var i = 0; i < myClass.length; i++) {
        if (myClass[i] === node) {
            return num;
        }
        num++;
    }
    return -1;
}
export const nthIndex = (str, pat, n) => {
    var L = str.length, i = -1;
    while (n-- && i++ < L) {
        i = str.indexOf(pat, i);
        if (i < 0) break;
    }
    return i;
}
export const formatPhoneNumber = (phone) => {
    if (phone.charAt(0) === '+') {
        return phone
    } else {
        return `+${phone}`
    }
}
export const Show422ErrorMessage = (errors) => {
    const keys = Object.keys(errors)
    keys.forEach((key) => {
        if (document.getElementsByClassName(`server-error ${key}`)) {
            document.getElementsByClassName(`server-error ${key}`)[0].innerHTML = errors[key][0]
        }
    });
}
