import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { AppContext } from "../../../context/appStateContext";
import { languages } from "../../../data/langauges";
import { Link } from "react-router-dom";
import { notification } from "antd";
class Competetions1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showEnrollAlert = this.showEnrollAlert.bind(this);
  }
  showEnrollAlert = () => {
    const { locale } = this.props;
    notification.error({
      message: languages[locale]["judge_can_not_participate"],
    });
  };

  render() {
    /*
        just a component to show the compotitions slider in home
        */
    const { locale } = this.props;
    const { context } = this.props;
    return (
      <React.Fragment>
        <div className="landing_competitions_section d-flex align-items-center">
          <div className="competions-container w-100">
            <div className="row mx-0">
              <div className="col-md-7 col-11 mx-md-5 px-lg-5 px-2 mx-auto">
                <div className="competitions-slider-wrapper">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    items={1}
                    nav={false}
                    dots={false}
                    autoplay={true}
                  >
                    {this.props.competitions.map((value, key) => (
                      <React.Fragment key={key}>
                        <div className="competition-item">
                          <div className="competition-logo-wrapper">
                            <div className="img-wrapper">
                              <div className="img-holder">
                                <img
                                  style={{ borderRadius: "50%" }}
                                  src={
                                    value.image
                                      ? value.image
                                      : "/images/competitions/quran.png"
                                  }
                                  alt="competition-logo"
                                />
                              </div>
                            </div>
                            <div className="title-wrapper mt-3">
                              <div className="font-Xlarge text-gold-450 weight-600 text-center">
                                {value.name}
                              </div>
                            </div>
                            <div className="description mt-4">
                              <div className="font-small weight-500 text-gold-400 text-center">
                                {value.description}
                              </div>
                            </div>
                            <div className="join-btn text-center mt-md-5 mt-4">
                              {localStorage["login_token"] ? (
                                localStorage["type"] === "JUDGE" ? (
                                  <span
                                    onClick={() => this.showEnrollAlert()}
                                    className="btn btn-rounded btn-gold-500 font-Lsmall px-5"
                                  >
                                    {languages[locale]["enroll_now"]}
                                  </span>
                                ) : (
                                  <Link
                                    to={`/${locale}/competition/${value.id}/${value.name}`}
                                  >
                                    <span className="btn btn-rounded btn-gold-500 font-Lsmall px-5">
                                      {languages[locale]["enroll_now"]}
                                    </span>
                                  </Link>
                                )
                              ) : (
                                <Link to={`/${locale}/auth`}>
                                  <span className="btn btn-rounded btn-gold-500 font-Lsmall px-5">
                                    {languages[locale]["enroll_now"]}
                                  </span>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default (props) => (
  <AppContext.Consumer>
    {(context) => {
      return <Competetions1 {...props} context={context} />;
    }}
  </AppContext.Consumer>
);
