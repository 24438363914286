import React, { Component } from "react";
import { Input, Select, notification, Spin } from "antd";
import { AppContext } from "../../../../context/appStateContext";
import axios from "../../../../helperComponents/axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ar from "react-phone-input-2/lang/ar.json";
import { languages } from "../../../../data/langauges";
// {languages[locale]['email']}
const { Option } = Select;
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};
const en = "";
const fields = [
  "first_name",
  "second_name",
  // "third_name",
  // "fourth_name",
  "email",
  "password",
  "password_confirmation",
  "phone",
  "type",
];
class RegisterCompetitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      cities: [],
      country_id: undefined,
      first_name: "",
      second_name: "",
      // third_name: "",
      // fourth_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      gender: undefined,
      phone: "",
      image: "",
      type: 1,
      formCount: 0,
      submited: false,
      errors: {
        first_name: "",
        second_name: "",
        // third_name: "",
        // fourth_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
      },
      gender_msg: "",
      country_msg: "",
      isLoading: false,
    };
    this.handleContinue = this.handleContinue.bind(this);
    this.renderRegisterForm = this.renderRegisterForm.bind(this);
    this.updateInput = this.updateInput.bind(this);
    this.updateCity = this.updateCity.bind(this);
    this.updateGender = this.updateGender.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);

    this.submit = this.submit.bind(this);
  }
  componentDidMount() {
    console.log("did mount");
    axios({
      method: "get",
      url: `/countries`,
    })
      .then((res) => {
        this.setState({ cities: res.data.data });
        console.log("RESPONSE RECEIVED: ", res);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  }
  updateInput(event, select_value) {
    const { locale } = this.props;
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "first_name":
        errors.first_name =
          value.length < 1 ? languages[locale]["this_field_required"] : "";
        break;
      case "second_name":
        errors.second_name =
          value.length < 1 ? languages[locale]["this_field_required"] : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : languages[locale]["wrong_email"];
        break;
      case "password":
        errors.password =
          value.length < 8 ? languages[locale]["min_8_chars"] : "";
        break;
      case "password_confirmation":
        errors.password_confirmation =
          value.length < 8 ? languages[locale]["min_8_chars"] : "";
        break;
      case "phone":
        errors.phone =
          value.length < 1 ? languages[locale]["this_field_required"] : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  }
  changePhone = (value) => {
    const { locale } = this.props;
    value = value.replace(/\s/g, "");
    let errors = this.state.errors;
    errors.phone =
      value.length < 1 ? languages[locale]["this_field_required"] : "";
    this.setState({ phone: value, errors });
  };
  updateCity(value) {
    this.setState({ country_id: value, country_msg: "" });
    console.log(this.state, value);
  }
  updateGender(value) {
    this.setState({ gender: value, gender_msg: "" });
  }
  renderRegisterForm() {
    const { locale } = this.props;
    const { errors } = this.state;
    if (this.state.step === 1) {
      return (
        <React.Fragment>
          <div className="row ">
            <div className="col-md-6 col-12">
              <div className="form-input-wrapper">
                <span style={{ color: "red" }}>*</span>
                <label className="text-purple-900 font-Lsmall">
                  {languages[locale]["first_name"]}
                </label>
                <div className="input-wrapper">
                  <Input
                    className="form-input"
                    style={{ fontFamily: "initial" }}
                    placeholder={languages[locale]["first_name"]}
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.updateInput}
                  />
                  {errors.first_name.length > 0 && (
                    <span
                      style={{ color: "red" }}
                      className="server-error font-small first_name"
                    >
                      {errors.first_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-input-wrapper">
                <span style={{ color: "red" }}>*</span>
                <label className="text-purple-900 font-Lsmall">
                  {languages[locale]["second_name"]}
                </label>
                <div className="input-wrapper">
                  <Input
                    className="form-input"
                    style={{ fontFamily: "initial" }}
                    placeholder={languages[locale]["second_name"]}
                    name="second_name"
                    value={this.state.second_name}
                    onChange={this.updateInput}
                  />
                  {errors.second_name.length > 0 && (
                    <span
                      style={{ color: "red" }}
                      className="server-error font-small second_name"
                    >
                      {errors.second_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-input-wrapper">
                <span style={{ color: "red" }}>*</span>
                <label className="text-purple-900 font-Lsmall">
                  {languages[locale]["email"]}
                </label>
                <div className="input-wrapper">
                  <Input
                    type="text"
                    className="form email"
                    style={{ fontFamily: "initial" }}
                    placeholder={languages[locale]["email"]}
                    name="email"
                    value={this.state.email}
                    onChange={this.updateInput}
                  />
                  {errors.email.length > 0 && (
                    <span
                      style={{ color: "red" }}
                      className="server-error font-small email"
                    >
                      {errors.email}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-input-wrapper">
                <span style={{ color: "red" }}>*</span>
                <label className="text-purple-900 font-Lsmall">
                  {languages[locale]["phone"]}
                </label>
                <div className="input-wrapper">
                  {/* <Input type='tel' className='form phone' style={{ fontFamily: 'initial', direction: 'ltr' }} placeholder='+966011XXXXXXX' name="phone" value={this.state.phone} onChange={this.updateInput} /> */}
                  <PhoneInput
                    excludeCountries={["il"]}
                    type="tel"
                    localization={locale == "ar" ? ar : en}
                    className="form-input phone_input px-0"
                    placeholder="+966011XXXXXXX"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.changePhone}
                  />
                  {errors.phone.length > 0 && (
                    <span
                      style={{ color: "red" }}
                      className="server-error font-small phone"
                    >
                      {errors.phone}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-input-wrapper">
                <span style={{ color: "red" }}>*</span>
                <label className="text-purple-900 font-Lsmall">
                  {languages[locale]["password"]}
                </label>
                <div className="input-wrapper">
                  <Input.Password
                    className="form password"
                    style={{ fontFamily: "initial" }}
                    placeholder={languages[locale]["password"]}
                    name="password"
                    value={this.state.password}
                    onChange={this.updateInput}
                  />
                  {errors.password.length > 0 && (
                    <span
                      style={{ color: "red" }}
                      className="server-error font-small password"
                    >
                      {errors.password}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-input-wrapper">
                <span style={{ color: "red" }}>*</span>
                <label className="text-purple-900 font-Lsmall">
                  {languages[locale]["confirm_password"]}
                </label>
                <div className="input-wrapper">
                  <Input.Password
                    className="form"
                    style={{ fontFamily: "initial" }}
                    placeholder={languages[locale]["confirm_password"]}
                    name="password_confirmation"
                    value={this.state.password_confirmation}
                    onChange={this.updateInput}
                  />
                  {errors.password_confirmation.length > 0 && (
                    <span
                      style={{ color: "red" }}
                      className="server-error font-small password_confirmation"
                    >
                      {errors.password_confirmation}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (this.state.step == 2) {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="form-input-wrapper">
                <span style={{ color: "red" }}>*</span>
                <label className="text-purple-900 font-Lsmall">
                  {languages[locale]["country"]}
                </label>
                <div className="input-wrapper">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="form-input"
                    placeholder={languages[locale]["country"]}
                    name="country_id"
                    onChange={this.updateCity}
                  >
                    {this.state.cities.map((city, i) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                  </Select>
                  {this.state.country_msg.length > 0 && (
                    <span
                      style={{ color: "red" }}
                      className="server-error font-small country_msg"
                    >
                      {this.state.country_msg}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-input-wrapper">
                <span style={{ color: "red" }}>*</span>
                <label className="text-purple-900 font-Lsmall">
                  {languages[locale]["gender"]}
                </label>
                <div className="input-wrapper">
                  <Select
                    className="form-input"
                    placeholder={languages[locale]["gender"]}
                    name="gender"
                    onChange={this.updateGender}
                  >
                    <Option value="1">{languages[locale]["male"]}</Option>
                    <Option value="2">{languages[locale]["female"]}</Option>
                  </Select>
                  {this.state.gender_msg.length > 0 && (
                    <span
                      style={{ color: "red" }}
                      className="server-error font-small gender_msg"
                    >
                      {this.state.gender_msg}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
  handleContinue() {
    const { locale } = this.props;
    let { errors } = this.state;
    if (this.state.step < 2) {
      if (validateForm(this.state.errors)) {
        let isValid = 1;
        fields.forEach((element) => {
          if (
            (element === "password" || element === "password_confirmation") &&
            this.state[element].length < 8
          ) {
            errors[element] = languages[locale]["min_8_chars"];
            isValid = 0;
          } else if (
            element === "password_confirmation" &&
            this.state[element] !== this.state["password"]
          ) {
            errors[element] = languages[locale]["password_not_identical"];
            isValid = 0;
          } else if (this.state[element].length < 1) {
            errors[element] = languages[locale]["this_field_required"];
            isValid = 0;
          }
        });
        this.setState({ errors });
        if (isValid) {
          // check if email or phone was used before
          this.setState({ isLoading: true });
          axios({
            method: "post",
            url: `/validate-unique-values`,
            data: {
              email: this.state.email,
              phone: this.state.phone,
            },
          })
            .then(() => {
              this.setState({ isLoading: false });
              // if all is well navigate to the second step
              this.setState({ step: this.state.step + 1 });
            })
            .catch((err) => {
              this.setState({ isLoading: false });
              // otherwise notify the user what was wrong
              if (err.response) {
                let obj = err.response.data.errors;
                for (let key in obj) {
                  if (obj.hasOwnProperty(key)) {
                    notification.error({
                      message: obj[key][0],
                    });
                  }
                }
              }
            });
        }
      }
    }
  }
  handlePrevious() {
    this.setState({ step: this.state.step - 1 });
  }
  submit(event) {
    event.preventDefault();

    const { locale } = this.props;
    let tempState = {},
      flag = false;
    if (!this.state.gender) {
      flag = true;
      tempState.gender_msg = languages[locale]["this_field_required"];
    }
    if (!this.state.country_id) {
      flag = true;
      tempState.country_msg = languages[locale]["this_field_required"];
    }
    if (flag) {
      this.setState(tempState);
      return;
    }
    this.setState({ submited: true });
    const data = {
      country_id: this.state.country_id,
      first_name: this.state.first_name,
      second_name: this.state.second_name,
      // here we submit dummy data as third and fourth name will not be used
      third_name: this.state.first_name,
      fourth_name: this.state.second_name,

      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      gender: this.state.gender,
      phone: this.state.phone,
      // image:this.state.image,
      type: this.state.type,
    };
    console.log(data);
    axios({
      method: "post",
      url: `/register`,
      data: data,
    })
      .then((res) => {
        // this.props.context.login(res.data.token);
        // this.props.context.updateUserInfo(res.data.data.id, res.data.data.first_name, res.data.data.second_name, res.data.data.image, res.data.data.type, res.data.data.status)
        notification.success({
          message: res.data.message,
        });
        this.props.history.push({
          pathname: `/${locale}/auth`,
          state: { type: "competitor" },
        });

        console.log("RESPONSE RECEIVED: register", res);
      })
      .catch((err) => {
        this.setState({ submited: false });
        if (
          err.response &&
          err.response.status == 422 &&
          err.response.data.errors.email
        ) {
          // document.getElementsByClassName('server-error email')[0].innerHTML = 'البريد الإلكترونى قد يكون خطأ'
          // document.getElementsByClassName('server-error password')[0].innerHTML = 'كلمة المرور قد يكون خطأ'
          console.log("AXIOS ERROR: register", err.response);
        }
        if (err.response) {
          let obj = err.response.data.errors;
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              notification.error({
                message: obj[key][0],
              });
            }
          }
        }

        console.log("AXIOS ERROR2: ", err);
      });
  }
  render() {
    const { locale } = this.props;
    return (
      <React.Fragment>
        <div
          className="register-page white-card shadow-card"
          style={{ borderRadius: "0px", minWidth: "300px" }}
        >
          <div className="form-wrapper">
            <form className="form" onSubmit={this.submit}>
              {this.renderRegisterForm()}

              <div className="btn-wrapper mt-2">
                {this.state.step === 2 ? (
                  <div className="row">
                    <div className="col-md-4 col-11 mx-auto">
                      <button
                        type={this.state.step === 2 ? "submit" : "button"}
                        disabled={this.state.step === 2 && this.state.submited}
                        onClick={this.handleContinue}
                        className="btn btn-purple-900 font-Lsmall weigh-500 w-100 mx-auto"
                      >
                        {this.state.submited && <Spin></Spin>}{" "}
                        {languages[locale]["continue"]}
                      </button>
                    </div>
                    <div className="col-md-4 col-11 mx-auto">
                      <button
                        onClick={this.handlePrevious}
                        disabled={this.state.step != 2}
                        type="button"
                        className="btn btn-purple-900 font-Lsmall weigh-500 w-100 mx-auto"
                      >
                        {languages[locale]["back"]}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-6 col-11 mx-auto">
                      <button
                        type={this.state.step === 2 ? "submit" : "button"}
                        onClick={this.handleContinue}
                        className="btn btn-purple-900 font-Lsmall weigh-500 w-100 mx-auto"
                        disabled={this.state.isLoading}
                      >
                        {this.state.isLoading && (
                          <>
                            <Spin></Spin>
                            &nbsp;
                          </>
                        )}
                        {languages[locale]["continue"]}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default (props) => (
  <AppContext.Consumer>
    {(context) => {
      return <RegisterCompetitor {...props} context={context} />;
    }}
  </AppContext.Consumer>
);
