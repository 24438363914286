import React, { Component, Fragment } from 'react';
import { AppContext } from '../../context/appStateContext'
import { NavLink, withRouter } from 'react-router-dom'
import { Drawer } from 'antd'
import { HashLink } from 'react-router-hash-link';
import { nthIndex } from '../../helperFunctions/functions'
import { languages } from '../../data/langauges'
import { MenuOutlined } from "@ant-design/icons"
class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile_nav_open: false
        }
        this.renderNav = this.renderNav.bind(this)
        this.renderNavDrawer = this.renderNavDrawer.bind(this)
    }
    renderNavContent() {
        const { window_width } = this.props.context.state
        const { locale } = this.props
        const sideListCompetitor = [{ text: languages[locale]['profile'], link: 'profile' },
        { text: languages[locale]['enrolled_in_competitons'], link: 'current-competitions' },
        { text: languages[locale]['rest_of_competitions'], link: 'history' }];

        const sideListResident = [{ text: languages[locale]['profile'], link: 'profile' },
        { text: languages[locale]['to_eval_competitons'], link: 'current-competitions' },
        { text: languages[locale]['ended_competitions'], link: 'ended' },
        { text: languages[locale]['evaluated_competitons'], link: 'history' }];
        const renderedList = localStorage['type'] === 'COMPETITOR' ? sideListCompetitor : sideListResident;
        return (
            <React.Fragment>
                <div className='nav-list' onClick={() => { this.setState({ mobile_nav_open: false }) }}>
                    <div className='nav-list-item'>
                        <NavLink to={`/${locale}`} className='nav-list-link font-small'>{languages[locale]['home']}</NavLink>
                    </div>

                    <div className='nav-list-item'>
                        <HashLink smooth to={`/${locale}/#about-us`} className='nav-list-link font-small '>{languages[locale]['about_us']}</HashLink>
                    </div>
                    <div className='nav-list-item'>
                        {localStorage['login_token'] ?
                            <NavLink to={`/${locale}/user/history`} className='nav-list-link font-small '>{languages[locale]['competitions']}</NavLink>
                            :
                            <HashLink smooth to={`/${locale}/#homeCompetitions`} className='nav-list-link font-small '>{languages[locale]['competitions']}</HashLink>
                        }
                    </div>

                    <div className='nav-list-item'>
                        <HashLink smooth to={`/${locale}/#contact-us`} className='nav-list-link font-small '>{languages[locale]['contact_us']}</HashLink>
                    </div>
                    {
                        window_width <= 991 && localStorage['login_token'] ?
                            <Fragment>
                                {
                                    renderedList.map((page, i) => (
                                        <div key={i} className='nav-list-item'>
                                            <NavLink to={`/${locale}/user/${page.link}`} className='nav-list-link font-small'>{page.text}</NavLink>
                                        </div>
                                    ))
                                }
                                <div className='nav-list-item' onClick={() => this.props.context.logout()}>
                                    <div className='nav-list-link font-small '>{languages[locale]['logout']}</div>
                                </div>
                            </Fragment>
                            : ''
                    }
                </div>
                {
                    localStorage['login_token'] ? ''
                        :
                        (<div className='login-btn-wrapper' onClick={() => { this.setState({ mobile_nav_open: false }) }}>
                            <NavLink to={`/${locale}/auth`} className='font-small btn btn-rounded btn-gold-400-outline px-5 weight-400'>{languages[locale]['login']}</NavLink>
                        </div>)}
                {
                    localStorage['login_token'] ?
                        (
                            <div className="nav-list-item text-center" onClick={() => { this.setState({ mobile_nav_open: false }) }}>

                                {
                                    localStorage['status'] !== 'APPROVED' ?
                                        <div className='nav-list-item' onClick={() => this.props.context.logout()}>
                                            <div className='nav-list-link font-small ' style={{ color: 'white', cursor: 'pointer' }}>{languages[locale]['logout']}</div>
                                        </div>
                                        : <NavLink to={`/${locale}/user`} className='nav-list-link font-small '><img width="40" height="40" style={{ borderRadius: '50%' }} src={localStorage['profile_picture'] && localStorage['profile_picture'] !== '' ? localStorage['profile_picture'] : '/images/user.png'} /></NavLink>
                                }
                            </div>
                        ) : ""}
                <div className='lang-wrapper mt-md-0 mt-3 ' onClick={() => { this.setState({ mobile_nav_open: false }) }}>

                    <a href={`/${locale == 'ar' ? 'en' : 'ar'}/${this.props.location.pathname.substring(nthIndex(this.props.location.pathname, '/', 2) + 1, this.props.location.pathname.length)}${this.props.location.search}`} className={` text-gold-400 weight-600 font-Lsmall ${locale == 'ar' ? 'font-family-en' : 'font-family-ar'} `}>{locale == 'ar' ? 'EN' : 'عربية'}</a>
                </div>

            </React.Fragment>
        )
    }
    renderNavDrawer() {
        const { locale } = this.props
        return (
            <Drawer
                id='mobile-nav'
                placement={locale == 'en' ? "right" : 'left'}
                closable={false}
                onClose={() => { this.setState({ mobile_nav_open: false }) }}
                visible={this.state.mobile_nav_open}
            >
                <div className='drawer-nav-body'>
                    {this.renderNavContent()}
                </div>
            </Drawer>
        )
    }
    renderNav() {
        const { navbar, window_width, windowScrollY } = this.props.context.state
        const { locale } = this.props
        return (
            <React.Fragment>
                <nav id='navbar'>
                    <div className='navbar-container'>
                        <div className='row mx-0'>
                            <div className='col-md-11 col-12 mx-auto'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='nav-brand'>
                                        <a className={`/${locale}/`}>
                                            <div className='nav-brand-img-wrapper'><img src='/images/logo/logo.svg' /></div>
                                        </a>
                                    </div>
                                    <div className='nav-content '>
                                        {
                                            window_width > 991 ?
                                                this.renderNavContent()
                                                :
                                                <div className='drower-btn-wrapper ml-auto'>
                                                    <button className='btn-transparent font-medium text-gold-400 weight-600' onClick={() => { this.setState({ mobile_nav_open: true }) }}><MenuOutlined /></button>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                {
                    window_width > 991 ?
                        <React.Fragment></React.Fragment>

                        :
                        this.renderNavDrawer()


                }
            </React.Fragment>
        )

    }
    render() {
        return (
            <React.Fragment>
                <header>
                    {this.renderNav()}
                </header>
            </React.Fragment>
        );
    }
}
export default withRouter(props => (<AppContext.Consumer>
    {(context) => {
        return <Navbar {...props} context={context} />
    }}
</AppContext.Consumer>))
