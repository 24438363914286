import React, { Component } from 'react';
class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    prevContentRender() {
        return (
            <React.Fragment>
                <div className="en section_container active" section-language="en">
                    <div id="md_en" data-target="out_en" className="agreement_md py-4">
                        <div className='section mb-5'>
                            <h1 className='mb-3 font-large text-purple-900 weight-700'>Privacy Policy</h1>
                            <p className='font-Lsmall weight-500 text-gray-500'>Last updated: September 27, 2020</p>
                            <p className='font-Lsmall weight-500 text-gray-500'>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                            <p className='font-Lsmall weight-500 text-gray-500'>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a className='link-purple-900' href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank">Privacy Policy Generator</a>.</p>
                        </div>

                        <div>
                            <h1 className='mb-4 font-Xlarge text-purple-900 weight-700'>Interpretation and Definitions</h1>
                            <h2 className='mb-3 font-large text-purple-900 weight-700'>Interpretation</h2>
                            <p className='font-Lsmall weight-500 text-gray-500'>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

                            <h2 className='mb-3 font-large text-purple-900 weight-700'>Definitions</h2>
                            <p className='font-Lsmall weight-500 text-gray-500'>For the purposes of this Privacy Policy:</p>
                            <ul className='px-4'>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Sabq, Saudi Arabia.</p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Country</strong> refers to: Saudi Arabia</p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Service</strong> refers to the Website.</p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Website</strong> refers to Sabq, accessible from <a className='link-purple-900' href="https://sabq.win/ar/" rel="external nofollow noopener" target="_blank">https://sabq.win/ar/</a></p>
                                </li>
                                <li>
                                    <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                                </li>
                            </ul>
                        </div>

                        <h1 className='mb-4 font-Xlarge text-purple-900 weight-700'>Collecting and Using Your Personal Data</h1>
                        <h2 className='mb-4 font-large text-purple-900 weight-700'>Types of Data Collected</h2>
                        <h3 className='mb-4 font-Lmedium text-purple-900 weight-700'>Personal Data</h3>
                        <p className='font-Lsmall weight-500 text-gray-500'>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                        <ul className='px-4'>
                            <li>
                                <p className='font-Lsmall weight-500 text-gray-500'>Email address</p>
                            </li>
                            <li>
                                <p className='font-Lsmall weight-500 text-gray-500'>First name and last name</p>
                            </li>
                            <li>
                                <p className='font-Lsmall weight-500 text-gray-500'>Phone number</p>
                            </li>
                            <li>
                                <p className='font-Lsmall weight-500 text-gray-500'>Address, State, Province, ZIP/Postal code, City</p>
                            </li>
                            <li>
                                <p className='font-Lsmall weight-500 text-gray-500'>Usage Data</p>
                            </li>
                        </ul>
                        <h3 className='mb-3 font-Lmedium text-purple-900 weight-700'>Usage Data</h3>
                        <p className='font-Lsmall weight-500 text-gray-500'>Usage Data is collected automatically when using the Service.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                        <h3 className='mb-3 font-Lmedium text-purple-900 weight-700'>Tracking Technologies and Cookies</h3>
                        <p className='font-Lsmall weight-500 text-gray-500'>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more about cookies: <a className='link-purple-900' href="https://www.termsfeed.com/blog/cookies/" target="_blank">All About Cookies</a>.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>We use both session and persistent Cookies for the purposes set out below:</p>
                        <ul className='px-4'>
                            <li>
                                <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Necessary / Essential Cookies</strong></p>
                                <p className='font-Lsmall weight-500 text-gray-500'>Type: Session Cookies</p>
                                <p className='font-Lsmall weight-500 text-gray-500'>Administered by: Us</p>
                                <p className='font-Lsmall weight-500 text-gray-500'>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
                            </li>
                            <li>
                                <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Cookies Policy / Notice Acceptance Cookies</strong></p>
                                <p className='font-Lsmall weight-500 text-gray-500'>Type: Persistent Cookies</p>
                                <p className='font-Lsmall weight-500 text-gray-500'>Administered by: Us</p>
                                <p className='font-Lsmall weight-500 text-gray-500'>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                            </li>
                            <li>
                                <p className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>Functionality Cookies</strong></p>
                                <p className='font-Lsmall weight-500 text-gray-500'>Type: Persistent Cookies</p>
                                <p className='font-Lsmall weight-500 text-gray-500'>Administered by: Us</p>
                                <p className='font-Lsmall weight-500 text-gray-500'>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
                            </li>
                        </ul>
                        <p className='font-Lsmall weight-500 text-gray-500'>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
                        <h2 className='mb-3 font-large text-purple-900 weight-700'>Use of Your Personal Data</h2>
                        <p className='font-Lsmall weight-500 text-gray-500'>The Company may use Personal Data for the following purposes:</p>
                        <ul className='px-4'>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
                        </ul>
                        <p className='font-Lsmall weight-500 text-gray-500'>We may share your personal information in the following situations:</p>
                        <ul className='px-4'>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>For Business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>With Business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                            <li className='font-Lsmall weight-500 text-gray-500'><strong className='text-purple-900'>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
                        </ul>
                        <h2 className='mb-3 font-large text-purple-900 weight-700'>Retention of Your Personal Data</h2>
                        <p className='font-Lsmall weight-500 text-gray-500'>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
                        <h2 className='mb-3 font-large text-purple-900 weight-700'>Transfer of Your Personal Data</h2>
                        <p className='font-Lsmall weight-500 text-gray-500'>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                        <h2 className='mb-3 font-large text-purple-900 weight-700'>Disclosure of Your Personal Data</h2>
                        <h3 className='mb-3 font-Lmedium text-purple-900 weight-700'>Business Transactions</h3>
                        <p className='font-Lsmall weight-500 text-gray-500'>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                        <h3 className='mb-3 font-Lmedium text-purple-900 weight-700'>Law enforcement</h3>
                        <p className='font-Lsmall weight-500 text-gray-500'>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                        <h3 className='mb-3 font-Lmedium text-purple-900 weight-700'>Other legal requirements</h3>
                        <p className='font-Lsmall weight-500 text-gray-500'>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                        <ul className='px-4'>
                            <li className='font-Lsmall weight-500 text-gray-500'>Comply with a legal obligation</li>
                            <li className='font-Lsmall weight-500 text-gray-500'>Protect and defend the rights or property of the Company</li>
                            <li className='font-Lsmall weight-500 text-gray-500'>Prevent or investigate possible wrongdoing in connection with the Service</li>
                            <li className='font-Lsmall weight-500 text-gray-500'>Protect the personal safety of Users of the Service or the public</li>
                            <li className='font-Lsmall weight-500 text-gray-500'>Protect against legal liability</li>
                        </ul>
                        <h2 className='mb-3 font-large text-purple-900 weight-700'>Security of Your Personal Data</h2>
                        <p className='font-Lsmall weight-500 text-gray-500'>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                        <h1 className='mt-md-5 mt-4 mb-3 font-Xlarge text-purple-900 weight-700'>Your California Privacy Rights (California's Shine the Light law)</h1>
                        <p className='font-Lsmall weight-500 text-gray-500'>Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>If you'd like to request more information under the California Shine the Light law, and if you are a California resident, You can contact Us using the contact information provided below.</p>
                        <h1 className='mt-md-5 mt-4 mb-3 font-Xlarge text-purple-900 weight-700'>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</h1>
                        <p className='font-Lsmall weight-500 text-gray-500'>California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>To request removal of such data, and if you are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.</p>
                        <h1 className='mt-md-5 mt-4 mb-3 font-Xlarge text-purple-900 weight-700'>Links to Other Websites</h1>
                        <p className='font-Lsmall weight-500 text-gray-500'>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                        <h1 className='mt-md-5 mt-4 mb-3 font-Xlarge text-purple-900 weight-700'>Changes to this Privacy Policy</h1>
                        <p className='font-Lsmall weight-500 text-gray-500'>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
                        <p className='font-Lsmall weight-500 text-gray-500'>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                        <h1 className='mt-md-5 mt-4 mb-3 font-Xlarge text-purple-900 weight-700'>Contact Us</h1>
                        <p className='font-Lsmall weight-500 text-gray-500'>If you have any questions about this Privacy Policy, You can contact us:</p>
                        <ul className='px-4'>
                            <li>
                                <p className='font-Lsmall weight-500 text-gray-500'>By email: <a href='mailto:Info@sabq.com' className='link-purple-900'>Info@sabq.com</a> </p>
                            </li>
                            <li>
                                <p className='font-Lsmall weight-500 text-gray-500'>By visiting this page on our website: <a className='link-purple-900' href="https://sabq.win/ar/#contact-us" rel="external nofollow noopener" target="_blank">https://sabq.win/ar/#contact-us</a></p>
                            </li>
                        </ul>
                    </div>
                    <div id="out_en" className="agreement_output"></div>
                </div>
            </React.Fragment>
        )
    }

    renderEnglishContent = () => (
        <React.Fragment>
            <div className="en section_container active" section-language="en" style={{ direction: "ltr", textAlign: 'left' }}>
                <div id="md_en" data-target="out_en" className="agreement_md py-4">
                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>General Terms</h1>
                        <p className='font-medium weight-500 text-gray-500'>The following terms include the official terms and conditions of the competition and its platform, which forms an integral part of the initiative. Thus, participating in the competition means that you are agreeing and complying to these terms and conditions and any matter relating to them, as well as the Platform’s Terms of Use.</p>

                        <ul className='px-4'>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Apple company not responsible at all to any affairs of the competitions or rewards of administration.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Competitions Management Team reserves the right, at its sole discretion, to cancel, extend and/or alter the process of the competition or its Terms and Conditions without prior notice and participants may not object or make any claims.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Any changes made to the Competition will be posted and announced on the official websites.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>All Participant submissions are subject to further review and examination to ensure their eligibility and conformity with the defined criteria, general procedures and terms, and conditions.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>All participants must commit to the specified start date and deadline of participation.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The participant acknowledges that he has reviewed and accepted the Terms and Conditions of the competition before participation, and the submission to any of the two awards will be recognized as acceptance of the Terms and Conditions stated herein.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Any Participant submission that proves to be in violation of intellectual property rights or copyright, whether through theft or plagiarism, will be excluded from the Competition either before or after the qualifying process and the Participant will be held accountable.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>If the competition is suspended, the organizers will post a message on all official channels and platform stating that the Program has been suspended.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Competition Management Team reserves the right to request further information and/or documents from the participants, if needed, to support or validate the credibility of the submission made.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>At any stage of the competition, the Competition Management Team reserves the right to exclude any participation that does not comply with the Terms and Conditions of the program.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The skill and creativity of the Participant are the only merits that qualify a Participant to win the Competition. Purchasing or using any external resources to support participation is not permitted.</li>

                        </ul>
                    </div>

                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>Participation Eligibility</h1>

                        <ul className='px-4'>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Participation is open for all ages and some competition may restrict to a specific age.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The <strong>members of wide technology</strong> and their business partners alongside the Competition Management Team are not allowed to participate in this competition.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Competition Management Team reserves the right to refrain from awarding the prize to the primary winner and to choose an alternative winner if the Participant is found to be ineligible according to the terms and conditions or general procedures of the Competition.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>All Participants must adhere to the ethical standards of honesty and transparency in all stages of the Competition. They are also obliged to attend the live performance phase (if required) and complete all phases in person, and no one is allowed to take the Participant’s place at any stage of any competition.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>A Participant cannot submit the same work or copy another participant’s work and submit it as if it is one's own. If such occurred, the participation will be denied.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>In the case of excluding a participant from the competition, the Competition Management Team has the right to choose an alternative participant. In this case, a selection is made by the jury according to the same criteria used in choosing the first participant.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Participant undertakes that all provided information is accurate, complete, and up-to-date.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Each participant can withdraw from the competition at any stage provided that an email is sent to <a className='link-purple-900 weight-600' href='mailto:info@sabq.com'>info@sabq.com</a> to request such.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Competition Management Team has the right to publish what they see fit from the participations during any stage of the competition for promotional purposes. </li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Competition Management Team is committed to not disclose any personal information of the participants to any third party, excluding legal claims or cooperation with any government entities under the legal conditions.</li>

                        </ul>
                    </div>

                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>Winners Selection</h1>

                        <ul className='px-4'>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Candidates will be contacted by telephone or e-mail to inform them of their nomination.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Candidates must review the competition's website to verify the validity of the information on which they were nominated.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Terms and Conditions of the competition apply to all candidates.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Communicating with or presenting a participant in any contest does not mean that the candidate has been selected as a winner. If at any point it is clear that the participant is not qualified, unable to continue the award or has been eliminated for any other reason, the Competition Management Team may choose an alternative participant.</li>

                        </ul>
                        <div className='section mb-5 mt-3 px-2'>
                            <h2 className='mb-3 font-large text-purple-900 weight-700'>Prizes</h2>

                            <ul className='px-4'>
                                <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Winners will be notified of winning, and candidates will be nominated for their prizes via e-mail or  mobile (call or message).</li>
                                <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The prize is awarded to the winners who have completed all stages of the competition and have been selected as winners. The prize is presented as a training program or tools and resources that enable the talented to train and develop in their field.</li>


                            </ul>
                        </div>
                    </div>

                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>Intellectual Property</h1>

                        <ul className='px-4'>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Competition Management Team will keep the submitted files attached to the competition participation form or ones sent by e-mail.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>In order to participate in any of the awards, the participant must agree to grant the rights to the wide technology or any entity authorized by it -including the Competition Management Team- to publish what it deems appropriate from the participations on the competition's website or in any other media, whether current or future, which may include websites, mobile phones, television or radio. And the participant agrees for it to be used in the context of promotional activities, advertising, and publicity. The participant has also agreed to grant the Competition Management Team a non-exclusive and irrevocable license for copying, publishing or transferring the participation with the right to edit, modify, rearrange, cut, translate and re-publish the participation for the purposes of the competition.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Participation must not violate any international or local rights, laws or provisions or in any way encourage violations of those rights, laws, and provisions.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Competition Management Team is not responsible for all illegal actions or violations exhibited by the participant before the official authorities.</li>
                        </ul>
                    </div>

                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>Legal Liability</h1>
                        <ul className='px-4'>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Competition Management Team is not held accountable for any loss, damage or disappointment suffered by the participant, including any damage that may be caused to him/her due to participating in the competition.</li>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>The Competition Management Team is not responsible for misinformation, inaccuracies, human errors, technical malfunctions, loss or delay of data transfer or deletion, or malfunction of the telephone line, computer, network, computer accessories, software or any other reasons that may disable website access.</li>
                        </ul>
                    </div>

                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>Authority</h1>
                        <ul className='px-4'>
                            <li className='font-Lsmall weight-500 text-gray-500 pb-2'>Any dispute that may arise regarding the rules, terms, and conditions contained here shall be settled amicably. If otherwise happened, the parties shall resort to arbitration by the Saudi law. The arbitration shall be exclusively for the Saudi system and jurisdiction exclusively for the Saudi judicial authorities, without regards to the domicile of the participant.</li>
                        </ul>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )


    renderArabicContent = () => (
        <React.Fragment>
            <div className="en section_container active" section-language="en" style={{ direction: "rtl", textAlign: 'right' }}>
                <div id="md_en" data-target="out_en" className="agreement_md py-4">
                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>الأحكام العامة</h1>
                        <p className='font-Lsmall weight-400 text-gray-500'>لشروط الواردة أدناه تتضمن شروط وأحكام المسابقات وتشكل جزءاً لا يتجزأ منها. وعليه، فإن الاشتراك فيه هذه المسابقات يعني الموافقة والالتزام التام بهذه الشروط والأحكام وبما يترتب عليها.</p>

                        <ul className='px-4'>
                            <li className='font-small weight-500 text-gray-500 pb-3'>تحتفظ الإدارة بحق إلغاء أو تمديد أو تعديل مجريات جميع مراحل المسابقة أو شروطها وأحكامها بموافقة الإدارة من دون إشعار مسبق ولا يحق للمشارك الاعتراض.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>أي تغييرات تجري في المسابقة سوف يتم نشرها على الموقع الرسمي <a className='link-purple-900 font-Lsmall weight-700' href='http://sabq.win'>sabq.win</a></li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>كل المشاركات التي تُقدم للمسابقة تخضع للمراجعة والتدقيق وذلك للتأكد من مدى أهليتها وموافقتها مع المعايير المحددة والتوجه العام والشروط والأحكام.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>على جميع المشاركين الالتزام بمواعيد بدء وانتهاء المدة المحددة لتقديم المشاركات.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>يُقرّ المشارك أنّه قد اطّلع ووافق على شروط وأحكام المسابقة قبل المشاركة ويعد التقديم على أي من المسابقات المطروحة إقرارًا بقبول الشروط والأحكام.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>أي مشاركة يثبت انتهاكها لحقوق الملكية الفكرية أو حقوق النشر سواء بالسرقة أو الانتحال فسيتم استبعادها من المسابقة سواء قبل عملية التصفيات أو بعدها، وسوف يعرض صاحب المشاركة نفسه للمساءلة القانونية.
في حال إيقاف مسابقة، يقوم منظمو مسابقة بنشر رسالة في القنوات الرسمية الخاصة بهم وعلى المنصة تفيد بإيقاف البرنامج.
</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>في حال طرأت الحاجة، يحق للإدارة المطالبة بالمزيد من المعلومات أو الوثائق لدعم المشاركة أو التحقق من المصداقية.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>في أي مرحلة من مراحل المسابقة، تحتفظ الإدارة بالحق في إقصاء أي مشاركة لا تتوافق مع شروط وأحكام البرنامج أو عملية التصفية أو التوجه العام.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>الفوز في المسابقة يعتمد على مهارة وإبداع المشارك فقط دون شراء واستخدام أي موارد خارجية تساعده على الفوز.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>لا تتعلق المسابقات وإدارتها وجوائزها وأي جزء من أجزائها بشركة أبل بأي شكل من الأشكال.</li>

                        </ul>
                    </div>

                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>أهلية المشاركين</h1>

                        <ul className='px-4'>
                            <li className='font-small weight-500 text-gray-500 pb-3'>المشاركة مفتوحة لكافة الأعمار في المسابقات وقد يتحدد عمر معين في بعض المسابقات</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>لا يحقّ لمنتسبي كل من التقنية الموسعة وشركاء العمل المشاركة.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>يحقّ لإدارة المسابقة عدم منح الجائزة للفائز الأساسي واختيار فائز بديل عنه إذا ما تبيّن عدم أهليّة المتسابق وفقاً للشروط والأحكام أو التوجه العام للمسابقة.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>يلتزم المشارك بالمصداقية التامة في جميع مراحل المسابقة، كما يلتزم بالحضورفي مرحلة الأداء الحي (إذا تطلب) وإكمال جميع المراحل بشخصه حيث لا يسمح لأي شخص أن ينوب مكانه في أي مرحلة من مراحل أي من المسابقة.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>لا يحق المشاركة بنفس العمل أو نسخه أو تكراره واقتباسه من مشارك لمشارك آخر، وفي حال حدوث ذلك يتم إلغاء المشاركة.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>في حالة إقصاء مشارك، يحق لإدارة المسابقة اختيار مشارك بديل. وفي هذه الحالة، يتم اختيار مشاركة من قبل لجنة التحكيم وفق المعايير ذاتها التي تمّ على أساسها اختيار المشارك الأساسي.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>يتعهد المشارك بأن تكون جميع المعلومات المقدمة من طرفه صحيحة وكاملة ومُحدَّثة.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>يمكن لكل مشارك الانسحاب من المسابقة في أي مرحلة بشرط إرسال إيميل إلى <a className='link-purple-900 weight-600 font-Lsmall' href='mailto:info@sabq.com'>info@sabq.com</a> لطلب الاستبعاد من المراحل المتبقّية. </li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>يحق لإدارة المسابقة نشر ما يرونه مناسباً من المعلومات المدخلة في نموذج التسليم عن المشاركين خلال أي مرحلة من المسابقة لأهداف ترويجية.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>إدارة المسابقة تلتزم بعدم إفشاء أي معلومات شخصية تخص المشاركين لأي طرف ثالث، يستثنى من ذلك المطالبات القانونية أو التعاون مع أي جهات حكومية بموجب مذكرة قانونية.</li>

                        </ul>
                    </div>

                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>اختيار الفائزين</h1>

                        <ul className='px-4'>
                            <li className='font-small weight-500 text-gray-500 pb-3'>سيتم التواصل مع المرشحين بالجوال أوالإيميل لإبلاغهم بترشحهم.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>يجب على المرشحين مراجعة موقع المسابقة للتحقق من صحة المعلومات التي تم ترشحيهم على أساسها.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>تطبق شروط وأحكام المسابقة على جميع المرشحين.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>التواصل مع المشارك أو تقديمه في المسابقة لا يعني أنه تم اختيار المرشح كفائز. لو اتضح في أي نقطة أن المشارك غير مؤهل، أو غير قادر على الاستمرار في المسابقة أو تم اقصائه لأي سبب آخر، يحق لإدارة المسابقة اختيار مشارك بديل.</li>

                        </ul>
                        <div className='section mb-5 mt-3 px-2'>
                            <h2 className='mb-3 font-Lmedium text-purple-900 weight-700'>الجوائز</h2>

                            <ul className='px-4'>
                                <li className='font-small weight-500 text-gray-500 pb-3'>يتم إبلاغ الفائزين عن فوزهم والمرشحين عن ترشيحهم للجائزة عبر البريد الإلكتروني أو الجوال (مكالمة أو رسالة).</li>
                                <li className='font-small weight-500 text-gray-500 pb-3'>تُمنح الجائزة للفائزين الذين قاموا بإنهاء جميع مراحل المسابقة، وتم اختيارهم كفائزين، ويتم تقديم قيمة الجائزة لهم بعد الانتهاء من إعلان الفائزين.</li>


                            </ul>
                        </div>
                    </div>

                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>الملكية الفكرية</h1>

                        <ul className='px-4'>
                            <li className='font-small weight-500 text-gray-500 pb-3'>سوف تحتفظ إدارة المسابقة بالملفات التفصيلية المرفقة ضمن استمارة المشاركة في المسابقة أو المرسلة بالبريد الإلكتروني.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>للمشاركة في أي من المسابقات، على المشارك الموافقة بمنح الحقوق لإدارة المسابقة، أو أي جهة تفوضها إدارة المسابقة بنشر ما تراه مناسباً من المشاركات على موقع المسابقة أو في أي وسيلة إعلامية أخرى سواء المعروفة حالياً أم الناشئة مستقبلاً مما قد يتضمن مواقع إلكترونية، هاتف جوال، تلفزيون، أو راديو أو غيرها من وسائل الإعلام، كما يوافق المشارك على استخدامها في إطار الأنشطة الترويجية والإعلانات والدعاية الإعلانية المتعلقة والخاصة بإدارة المسابقة. ويكون أيضاً المشارك قد وافق على منح إدارة المسابقة ترخيصاً غير حصريّ ولا رجوع فيه بغرض نسخ أو نشر أو نقل المشاركة مع أحقية تحرير وتعديل وإعادة ترتيب وقص وترجمة المشاركة وإعادة إخراجها للأغراض الخاصة بالمسابقة.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>يجب ألا تنتهك المشاركة أي حقوق أو قوانين أو أحكام دولية أو محلية أو تشجع بأي شكل من الأشكال على انتهاك تلك الحقوق والقوانين والأحكام.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>إدارة المسابقة غير مسؤولة عن كافة الإجراءات القانونية تجاه انتهاك أي قوانين قد يكون المشارك انتهكها أمام الجهات الرسمية.</li>
                        </ul>
                    </div>

                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>المسؤولية القانونية</h1>
                        <ul className='px-4'>
                            <li className='font-small weight-500 text-gray-500 pb-3'>لا تتحمل إدارة المسابقة أي مسؤولية عن أي خسائر مادية أو أيّ ضرر أو خيبة أمل يتكبدها المشارك ناتجة عن المشاركة في أي من المسابقات.</li>
                            <li className='font-small weight-500 text-gray-500 pb-3'>لا تتحمل إدارة المسابقة أي مسؤولية إذا تم تسليم معلومات خاطئة، غير دقيقة أو أخطاء بشرية أو أعطال فنية أو فقدان أو تأخير نقل البيانات أو حذفها، أو عطلها أو عطل في الإنترنت، الكمبيوتر أو الشبكة، أو ملحقات الكمبيوتر، أو برامج أو أي أسباب أخرى قد تُعطل دخول الموقع.</li>
                        </ul>
                    </div>

                    <div className='section mb-5'>
                        <h1 className='mb-3 font-large text-purple-900 weight-700'>الاختصاص</h1>
                        <ul className='px-4'>
                            <li className='font-small weight-500 text-gray-500 pb-3'>كلّ نزاع قد ينشأ بشأن القواعد والشروط والأحكام الواردة في هذه الوثيقة تتم تسويته ودياً. وفي حال تعذّر الحل الودّي، يلجأ الطرفين إلى التحكيم وفقاً للنظام السعودي ويكون التحكيم حصراً للنظام السعودي والاختصاص حصراً للجهات القضائية السعودية وذلك دون اعتبار لموطِن المتسابق.</li>
                        </ul>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
    render() {
        return (
            <React.Fragment>

                <section className='privacy-policy-page'>
                    <div className='bg-container'></div>
                    <div className='py-4'>
                        <div className='row mx-0'>
                            <div className='col-md-9 col-12 mx-auto'>
                                {
                                    this.renderArabicContent()
                                }
                                {
                                    this.renderEnglishContent()
                                }
                            </div>
                        </div>
                    </div>


                </section>
            </React.Fragment>
        );
    }
}

export default PrivacyPolicy;