import React, { Component } from "react";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";
import RegisterResident from "./resident";
import RegisterCompetitor from "./competitor";
import { languages } from "../../../data/langauges";
// {languages[locale]['email']}
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      register_type: "competitor",
    };
    this.renderRegisterChoices = this.renderRegisterChoices.bind(this);
    this.changeType = this.changeType.bind(this);
    this.redirectToRegister = this.redirectToRegister.bind(this);
  }
  changeType(event) {
    const value = event.target.value;
    this.setState({ register_type: value });
  }
  redirectToRegister() {
    const { locale } = this.props;
    // console.log(this.props)
    if (this.state.register_type === "resident") {
      this.props.history.push(`/${locale}/auth/register/resident`);
    } else {
      this.props.history.push(`/${locale}/auth/register/competitor`);
    }
  }
  renderRegisterChoices(locale) {
    return (
      <React.Fragment>
        <div
          className="choose-register-page white-card shadow-card"
          style={{ borderRadius: "0px" }}
        >
          <div className="register-type-wrapper">
            <label className="register-choice">
              <input
                type="radio"
                className="d-none register-radio"
                name="register_type"
                defaultChecked={this.state.register_type == "resident"}
                value="resident"
                onClick={this.changeType}
              />
              <div className="register-choice-label">
                <div className="img-wrapper">
                  <img src="/images/figuers/court.svg" className="svg" />
                </div>
                <div className="label-text text-center font-small weight-600">
                  {languages[locale]["judge"]}
                </div>
              </div>
            </label>
            <label className="register-choice">
              <input
                type="radio"
                className="d-none register-radio"
                name="register_type"
                defaultChecked={this.state.register_type == "competitor"}
                value="competitor"
                onClick={this.changeType}
              />
              <div className="register-choice-label">
                <div className="img-wrapper">
                  <img src="/images/figuers/employee.svg" className="svg" />
                </div>
                <div className="label-text text-center font-small weight-600">
                  {languages[locale]["competitor"]}
                </div>
              </div>
            </label>
          </div>
          <div className="btn-wrapper">
            <button
              className="btn btn-purple-900 font-Lsmall weight-500 w-100"
              onClick={this.redirectToRegister}
            >
              {languages[locale]["continue"]}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
  render() {
    const { locale } = this.props;
    return (
      <React.Fragment>
        <Switch>
          <Route
            path={`/${locale}/auth/register`}
            exact
            render={(props) => this.renderRegisterChoices(locale)}
          />
          <Route
            path={`/${locale}/auth/register/resident`}
            render={(props) => <RegisterResident {...props} locale={locale} />}
          />
          <Route
            path={`/${locale}/auth/register/competitor`}
            render={(props) => (
              <RegisterCompetitor {...props} locale={locale} />
            )}
          />

          {/* default */}
          <Route
            render={(props) => <Redirect to={`/${locale}/auth/register`} />}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Register;
